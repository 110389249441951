<template>
  <div>
    <div v-if="!isMobile" style="max-width: 1200px;margin:0 auto">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{path: '/robot'}" :replace="false">{{ items.robot_type == 'bn'?'Binance':'OKX' }}</el-breadcrumb-item>
        <el-breadcrumb-item>{{ $t('robot.strategy_details') }}<span style="display: inline-block;margin-left: 10px">(NO.{{items.rank<10?'0'+items.rank:items.rank}})</span></el-breadcrumb-item>
      </el-breadcrumb>

      <el-card style="margin-top: 12px" shadow="hover">
        <div class="detail_title">
          <img src="../../assets/img/robot/robot_strategy/strategy_contract.svg">
          <span>{{ dataItem.coin }} <span style="color: #52C41A">⇀</span> {{ items.coin_two }} 永续10X</span>
          <span>{{ $t('robot.auto') }}：{{ $t('robot.grid_contract_strategy') }}</span>

          <span v-if="(items.strategyType == 0 && items.state == 1 && items.capital_one == 0)" style="display:inline-block;vertical-align:middle;font-size:12px;color: #AB54DB;margin:0 10px"><span class="el-icon-refresh-right" style="color: #AB54DB;margin-right:2px;font-size: 16px;animation: rotating 1.2s linear infinite;vertical-align: top;font-weight: unset;"></span>正在初始化</span>
          <img v-if="(items.state == 0)" style="vertical-align: middle;margin-left: 16px"src="../../assets/img/robot/running.gif">
          <span style="vertical-align: middle">{{ $t('robot.create_time') }} {{ items.add_time }}</span>
          <div v-if="switchValue != 2">
            <el-button class="detail_title_button_w" @click="delet(items)">{{ $t('robot.del_strategy') }}</el-button>
            <el-button v-if="items.state == 1" class="detail_title_button_b" @click="dialogPause()">{{ $t('robot.pause_strategy') }}</el-button>
            <el-button v-if="items.state == 2" class="detail_title_button_b" @click="dialogOpen()">{{ $t('robot.open_strategy') }}</el-button>
          </div>
        </div>
      </el-card>

      <el-card class="total_revenue" style="margin-top: 0px;position: relative" shadow="hover">
        <div class="total_revenue_content">
          <div style="">
            <div style="display: inline-block; width: 33%;">
              <el-tooltip effect="dark" :content="$t('robot.hedge_detail_tip1')" placement="top">
                <span style="margin-bottom: 8px; border-bottom: #b4bccc 1px dashed; width: fit-content">{{ $t('robot.Total_revenue') }}</span>
              </el-tooltip>
              <span style="font-size: 16px;font-weight: 600;color: #1E2329;">{{ dataItem.profit_withdraw }} {{ dataItem.coin }}</span>
            </div>
            <div style="display: inline-block; text-align: center; width: 33%; padding-right: 0">
              <el-tooltip effect="dark" :content="$t('robot.hedge_detail_tip2')" placement="top">
                <span style="margin: 0 auto 8px; border-bottom: #b4bccc 1px dashed; width: fit-content">{{ $t('robot.billing_quantity') }}</span>
              </el-tooltip>
              <span  style="font-size: 16px;font-weight: 600;color: #1E2329;">{{ dataItem.profit_times }} {{ $t('robot.order') }}</span>
            </div>
<!--            <div style="text-align: center">-->
<!--              <span style="margin-bottom: 8px">{{ $t('robot.profit_withdrawn') }}</span>-->
<!--              <span style="font-size: 16px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #1E2329;">{{ dataItem.profit_withdraw }} {{ dataItem.coin }}</span>-->
<!--            </div>-->
            <div class="" style="display: inline-block; width: 33%; text-align: right">
              <el-tooltip effect="dark" :content="$t('robot.hedge_detail_tip3')" placement="top">
                <span style="display: inline-block; margin-bottom: 8px; border-bottom: #b4bccc 1px dashed; width: max-content">{{ $t('robot.yield') }} | {{ $t('robot.total_annualization') }}</span>
              </el-tooltip>
              <span v-if="dataItem.profit_rate > 0" style="font-size: 16px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #52C41A;">{{ dataItem.profit_rate }}% | {{ dataItem.profit_rate_year }}%</span>
              <span v-if="dataItem.profit_rate == 0" style="font-size: 16px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #1E2329;">{{ dataItem.profit_rate }}% | {{ dataItem.profit_rate_year }}%</span>
              <span v-if="dataItem.profit_rate < 0" style="font-size: 16px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #FF4D4F;">{{ dataItem.profit_rate }}% | {{ dataItem.profit_rate_year }}%</span>
            </div>
          </div>
          <div style="width: 97%;height: 1px;background: #EAECEF;margin: 20px 3% 20px 0"></div>
          <div style="width: 100%; position:relative;color: #72777E; display: flex; justify-content: space-between">
<!--            <div style="display: inline-block;width: 33%;vertical-align: middle;">-->
<!--              <el-tooltip effect="dark" :content="$t('robot.hedge_detail_tip4')" placement="top">-->
<!--                <span style="margin-bottom: 8px;width: fit-content;border-bottom: #b4bccc 1px dashed;">{{ $t('robot.position_floating') }}</span>-->
<!--              </el-tooltip>-->
<!--              <span class="total_revenue_content_bottom" style="color: #1E2329">{{ dataItem.profit_lose }} {{ dataItem.coin }}</span>-->
<!--            </div>-->
            <div style="display: inline-block;width: 33%;vertical-align: middle;text-align: left">
              <el-tooltip effect="dark" :content="$t('robot.hedge_detail_tip5')" placement="top">
                <span style="display: inline-block; margin-bottom: 8px;width: fit-content;border-bottom: #b4bccc 1px dashed;">{{ $t('robot.investment_amount') }}</span>
              </el-tooltip>
              <span class="total_revenue_content_bottom" style="color: #1E2329">{{ dataItem.space }} {{ dataItem.coin }}</span>
            </div>
            <div style="display: inline-block;width: 33%;vertical-align: middle; text-align: right">
              <el-tooltip effect="dark" :content="$t('robot.hedge_detail_tip6')" placement="top">
                <span style="display: inline-block; margin-bottom: 8px;width: max-content;border-bottom: #b4bccc 1px dashed;">{{ $t('robot.exchange_assets') }}</span>
              </el-tooltip>
              <span class="total_revenue_content_bottom" style="color: #1E2329">{{ dataItem.exchange_balance }} {{ dataItem.coin }}</span>
            </div>
            <!--          <el-button class="detail_title_button_w" style="width: 16%;margin-right: 3%;vertical-align: middle;">查看排行</el-button>-->
          </div>
        </div>
<!--        <div class="total_revenue_content" style="border-left: 1px #EAECEF solid;position:absolute;padding-left: 1%;">-->
<!--          <charts-line></charts-line>-->
<!--        </div>-->
      </el-card>

      <el-card class="total_revenue" style="margin-top: 0px;position: relative" shadow="hover">
        <div class="total_revenue_content" style="width: 100%">
          <span style="font-size: 14px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #1E2329;">{{ $t('robot.strategy_details') }}</span>
          <div style="width: 100%;height: 1px;background: #EAECEF;margin: 16px 3% 16px 0"></div>
          <div style="width: 100%; position:relative; display: flex; justify-content: space-between">
            <div style="display: inline-block;width: 33%;vertical-align: middle;">
<!--              <span style="margin-bottom: 8px">{{ $t('robot.futures_currently_held') }}</span>-->
<!--              <div style="display: inline-block;width: fit-content">-->
<!--                <span class="total_revenue_content_bottom" style="display:inline-block;;width: fit-content;color: #1E2329;margin-right: 6px;vertical-align: middle">{{ dataItem.symbol }}</span>-->
<!--                <span style="background: #F0F4FB;;width: fit-content;border-radius: 2px;font-size:12px;padding:0 3px;color: #666666;display: inline-block">{{$t('robot.cross')}}</span>-->
<!--                <span style="display: inline-block;background: rgba(28, 197, 141, 0.1);border-radius: 2px;font-size:12px;width: fit-content;margin-left: 6px;color: #1CC58D;padding: 0 3px">{{ $t('robot.long') }} {{ dataItem.leverage }}x</span>-->
<!--                <span style="display: inline-block;font-size: 14px;color: #1E2329;width: fit-content;margin-left: 6px;">{{ dataItem.long_space }}{{ $t('robot.cont') }}</span>-->
<!--              </div>-->
<!--              <div style="display: inline-block;text-align: left;width: fit-content;margin-left: 20px">-->
<!--                <span class="total_revenue_content_bottom" style="display:inline-block;;width: fit-content;color: #1E2329;margin-right: 6px;vertical-align: middle">{{ dataItem.symbol }}</span>-->
<!--                <span style="background: #F0F4FB;;width: fit-content;border-radius: 2px;font-size:12px;padding:0 3px;color: #666666;display: inline-block">{{$t('robot.cross')}}</span>-->
<!--                <span style="display: inline-block;background: rgba(255, 71, 63, 0.1);border-radius: 2px;font-size:12px;width: fit-content;margin-left: 6px;color: #FF473F;padding: 0 3px">{{ $t('robot.short') }} {{ dataItem.leverage }}x</span>-->
<!--                <span style="display: inline-block;font-size: 14px;color: #1E2329;width: fit-content;margin-left: 6px;">{{ dataItem.short_space }}{{ $t('robot.cont') }}</span>-->
<!--              </div>-->
              <span style="margin-bottom: 5px">{{ $t('robot.trade_currency') }}</span>
              <div style="display: inline-block;width: fit-content">
                <span class="total_revenue_content_bottom" style="display:inline-block;;width: fit-content;color: #1E2329;margin-right: 6px;vertical-align: middle">{{ dataItem.symbol }}</span>
                <span style="display: inline-block;width: fit-content;margin-left: 6px;">{{ $t('robot.perpetual') }}</span>
                <span style="display: inline-block;width: fit-content;margin-left: 6px;">{{ dataItem.leverage }}X</span>
              </div>

<!--              <span class="total_revenue_content_bottom" style="display:inline-block;color: #1E2329">{{ dataItem.symbol }}{{ $t('robot.short_order') }} {{ dataItem.short_space }}{{ $t('robot.cont') }}</span>-->
            </div>

<!--            <div style="display: inline-block;width: 20%;vertical-align: middle;">-->
<!--              <span style="margin-bottom: 8px">{{ $t('robot.margin_rate') }}</span>-->
<!--              <span class="total_revenue_content_bottom" style="display:inline-block;color: #1E2329;margin-right: 20px">{{ dataItem.margin_rate?dataItem.margin_rate:'0.00' }}%</span>-->
<!--            </div>-->

<!--            <div style="display: inline-block;width: 15%;vertical-align: middle;">-->
<!--              <span style="margin-bottom: 8px">{{ $t('robot.loss') }}</span>-->
<!--              <span class="total_revenue_content_bottom" style="display:inline-block;color: #1E2329;margin-right: 20px">{{ (dataItem.stop_loss || dataItem.stop_loss == 0)?dataItem.stop_loss !=0?(dataItem.stop_loss*100).toFixed(2)+'%':$t('robot.not_stop_loss'):'' }}</span>-->
<!--            </div>-->

            <div style="display: inline-block;width: 33%;vertical-align: middle;text-align: center">
              <span style="margin-bottom: 5px">{{ $t('robot.stop_loss') }}</span>
              <span class="total_revenue_content_bottom" style="display:inline-block;color: #1E2329;">{{ (dataItem.stop_loss || dataItem.stop_loss == 0)?dataItem.stop_loss ==100?$t('robot.stop_loss'):dataItem.stop_loss==5?'部分止损':$t('robot.no_stop_loss'):'' }}</span>
            </div>

            <div style="display: inline-block;width: 33%;vertical-align: middle;text-align: center">
<!--              <span style="margin-bottom: 5px">{{ $t('robot.interference') }}</span>-->
<!--              <span class="total_revenue_content_bottom" style="display:inline-block;color: #1E2329;">{{ (dataItem.stop_surplus || dataItem.stop_surplus == 0)?dataItem.stop_surplus !=0?(dataItem.stop_surplus*100)+'%':$t('robot.not_stop_surplus'):'' }}</span>-->
            </div>
          </div>

          <div style="width: 100%; position:relative; display: flex; justify-content: space-between; margin-top: 16px">
            <div style="display: inline-block;width: 33%;vertical-align: middle;">
              <span style="margin-bottom: 5px">{{ $t('robot.make_up_loss_ratio') }}</span>
              <span class="total_revenue_content_bottom" style="display:inline-block;color: #1E2329;">{{ dataItem.loss_transfer_rate }}%</span>
            </div>
            <div style="display: inline-block;width: 33%;vertical-align: middle;text-align: center">
              <span style="margin-bottom: 5px">{{ $t('robot.risk_level') }}</span>
              <span class="total_revenue_content_bottom" style="display:inline-block;color: #1E2329;">{{dataItem.position_proportion?dataItem.position_proportion<0.6?'低':dataItem.position_proportion>0.8?'高':'中':''}}({{ dataItem.position_proportion?(dataItem.position_proportion * 100):'' }}%)</span>
            </div>
            <div style="display: inline-block;width: 33%;vertical-align: middle;">
              <div style="position:absolute;right: 0; text-align: right">
                <span style="margin-bottom: 5px">{{ $t('robot.trading_place') }}</span>
                <span class="total_revenue_content_bottom" style="color: #1E2329;">{{ items.robot_type == 'bn' ? 'Binance' : 'OKX' }}</span>
              </div>
            </div>
          </div>
        </div>
      </el-card>

      <el-card class="" shadow="hover">
        <div class="everyday-top">
          <el-tabs v-model="lishijiaoyi" @tab-click="handleSelect">
            <el-tab-pane :label="lishitotal3?$t('robot.positions')+'('+ lishitotal3 +')':$t('robot.positions')" name="2"></el-tab-pane>
            <el-tab-pane :label="lishitotal4?$t('robot.position_history')+'('+ lishitotal4 +')':$t('robot.position_history')" name="3"></el-tab-pane>
            <el-tab-pane :label="lishitotal?$t('robot.current_delegation')+'('+ lishitotal +')':$t('robot.current_delegation')" name="0"></el-tab-pane>
            <el-tab-pane :label="lishitotal2?$t('robot.history_delegation')+'('+ lishitotal2 +')':$t('robot.history_delegation')" name="1"></el-tab-pane>
          </el-tabs>
        </div>
        <div class="robot-pc">
          <el-select disabled v-if="lishijiaoyi == 0 || lishijiaoyi == 1" v-model="orderType" @change="changeOrderType">
            <el-option :key="2" :label="$t('robot.limit_market')" :value="2"></el-option>
            <el-option :key="1" :label="$t('robot.stop_profit_stop_loss')" :value="1"></el-option>
          </el-select>
          <el-date-picker
              v-if="lishijiaoyi != 0 && lishijiaoyi != 2"
              style="float:right;"
              v-model="dateValue"
              :clearable="false"
              type="daterange"
              value-format="yyyy-MM-dd"
              range-separator="-"
              :start-placeholder="$t('robot.start_date')"
              :end-placeholder="$t('robot.end_date')"
              @change="selectDate">
          </el-date-picker>
        </div>

        <div class="robot-pc">
          <!-- 当前委托  历史委托 -->
          <el-table v-if="lishijiaoyi == 0 || lishijiaoyi == 1" :key="lishijiaoyi" :data="lishijiaoyiList_1" style="width: 100%">
            <el-table-column v-if="lishijiaoyi == 0||lishijiaoyi == 1" align="center" prop="create_time" :label="$t('robot.entrusted_time')">
              <template slot-scope="scope" >
                <span style="word-break: break-word">{{ scope.row.create_time }}</span>
              </template>
            </el-table-column>
            <el-table-column v-if="lishijiaoyi == 0||lishijiaoyi == 1" align="center" width="90" prop="deal_time" :label="$t('robot.business_line')">
              <template>{{ $t('robot.perpetual') }}</template>
            </el-table-column>
            <el-table-column v-if="lishijiaoyi == 0||lishijiaoyi == 1" align="center" prop="symbol" :label="$t('robot.transaction_pair')">
              <template>{{ dataItem.symbol }}</template>
            </el-table-column>
            <el-table-column v-if="lishijiaoyi == 0||lishijiaoyi == 1" align="center" prop="instrument_id" :label="$t('robot.position_type')">
              <template>{{ $t('robot.cross') }} {{ dataItem.leverage }}x</template>
            </el-table-column>
            <el-table-column v-if="lishijiaoyi == 0||lishijiaoyi == 1" align="center" prop="side" :label="$t('robot.side')">
              <template slot-scope="scope">
                <span :class="[scope.row.side === 'buy'?'side_color_green':'side_color_red']" v-if="scope.row.type == 'open' && scope.row.side == 'buy'">买入开多</span>
                <span :class="[scope.row.side === 'buy'?'side_color_green':'side_color_red']" v-if="scope.row.type == 'open' && scope.row.side == 'sell'">卖出开空</span>
                <span :class="[scope.row.side === 'sell'?'side_color_green':'side_color_red']" v-if="scope.row.type == 'close' && scope.row.side == 'buy'">卖出平多</span>
                <span :class="[scope.row.side === 'sell'?'side_color_green':'side_color_red']" v-if="scope.row.type == 'close' && scope.row.side == 'sell'">买入平空</span>
              </template>
            </el-table-column>
            <el-table-column v-if="lishijiaoyi == 0" align="center" prop="size" :label="$t('robot.num')">
                <template slot-scope="scope">
                  <span>{{ scope.row.size }}{{$t('robot.cont')}}</span>
                </template>
            </el-table-column>
            <el-table-column v-if="lishijiaoyi == 1" align="center" prop="size" :label="$t('robot.entrusted_quantity')">
              <template slot-scope="scope">
                <span>{{ scope.row.size }}{{$t('robot.cont')}}</span>
              </template>
            </el-table-column>
            <el-table-column v-if="lishijiaoyi == 0||lishijiaoyi == 1" align="center" prop="price" :label="$t('robot.price')+' '+(dataItem.coin?dataItem.coin:'')">
            </el-table-column>
            <el-table-column v-if="lishijiaoyi == 0||lishijiaoyi == 1" align="center" prop="" :label="$t('robot.order_Value')+' '+(dataItem.coin?dataItem.coin:'')">
              <template slot-scope="scope">
                <span>{{ scope.row.total_money }}</span>
              </template>
            </el-table-column>
            <el-table-column v-if="lishijiaoyi == 1" align="center" prop="update_time" :label="$t('robot.transaction_time')">
              <template slot-scope="scope" >
                <span style="word-break: break-word">{{ scope.row.update_time }}</span>
              </template>
            </el-table-column>
            <el-table-column v-if="lishijiaoyi == 1" align="center" prop="earn" :formatter="stateFormat3" :label="$t('robot.state')">
            </el-table-column>
          </el-table>
          <el-pagination
              v-if="lishijiaoyi == 0 || lishijiaoyi == 1"
              style="text-align: right;margin: 24px"
              layout="prev, pager, next"
              @current-change="handleRecommendCurrentChange"
              :page-size="10"
              :current-page="pageNum"
              :hide-on-single-page="capitalListTotal<=0"
              :total="capitalListTotal">
          </el-pagination>
          <!-- 仓位  历史仓位 -->
          <el-table v-if="lishijiaoyi == 2 || lishijiaoyi == 3" :key="lishijiaoyi" :data="lishijiaoyiList_1" style="width: 100%">
            <el-table-column v-if="lishijiaoyi == 2" align="center" prop="symbol" :label="$t('robot.transaction_pair')">
            </el-table-column>
            <el-table-column v-if="lishijiaoyi == 2" align="center" prop="symbol" :label="$t('robot.business_line')">
              <template>{{ $t('robot.perpetual') }}</template>
            </el-table-column>
            <el-table-column v-if="lishijiaoyi == 2" align="center" prop="instrument_id" :label="$t('robot.position_type')">
              <template slot-scope="scope">
                <span>{{ $t('robot.cross') }}</span>
                <span :class="[scope.row.side === 'buy'?'side_color_green':'side_color_red']" style="margin-left: 5px;font-size: 12px">{{ scope.row.side === 'buy'?$t('robot.long'):$t('robot.short') }} {{ scope.row.leverage }}x</span>
              </template>
            </el-table-column>
            <el-table-column v-if="lishijiaoyi == 2" align="center" prop="total_size" :label="$t('robot.total_position')">
              <template slot-scope="scope">{{ scope.row.total_size }}{{ $t('robot.cont') }}</template>
            </el-table-column>
            <el-table-column v-if="lishijiaoyi == 2" align="center" prop="avg_price" :label="$t('robot.avg_price')">
            </el-table-column>
            <el-table-column v-if="lishijiaoyi == 2" align="center" prop="initial_margin" :label="$t('robot.IMR')">
            </el-table-column>
            <el-table-column v-if="lishijiaoyi == 2" align="center" prop="mark_price" :label="$t('robot.mark_price')">
            </el-table-column>
            <el-table-column v-if="lishijiaoyi == 2" align="center" prop="mark_price" :label="$t('robot.margin_rate')">
              <template slot-scope="scope">{{ scope.row.margin_ratio?scope.row.margin_ratio:'0.00' | roundTwoFilter }}%</template>
            </el-table-column>
            <el-table-column v-if="lishijiaoyi == 2" align="center" prop="mark_price" :label="$t('robot.liq_price')">
              <template slot-scope="scope">{{ scope.row.force_price?scope.row.force_price  : '--' }}</template>
            </el-table-column>
            <el-table-column v-if="lishijiaoyi == 2" align="center" prop="mark_price" :label="$t('robot.pl')">
              <template slot-scope="scope">{{ scope.row.profit | roundTwoFilter }}</template>
            </el-table-column>
            <el-table-column v-if="lishijiaoyi == 2" align="center" prop="mark_price" :label="$t('robot.yield')">
              <template slot-scope="scope">{{ scope.row.profit_rate | roundTwoFilter }}%</template>
            </el-table-column>

            <el-table-column v-if="lishijiaoyi == 3" align="center" prop="create_time" :label="$t('robot.opened')">
              <template slot-scope="scope" >
                <span style="word-break: break-word">{{ scope.row.start_time }}</span>
              </template>
            </el-table-column>
            <el-table-column v-if="lishijiaoyi == 3" align="center" prop="perpetual" :label="$t('robot.business_line')">
              <template>{{ $t('robot.perpetual') }}</template>
            </el-table-column>
            <el-table-column v-if="lishijiaoyi == 3" align="center" prop="symbol" :label="$t('robot.transaction_pair')">
              <template>{{ dataItem.symbol }}</template>
            </el-table-column>
            <el-table-column v-if="lishijiaoyi == 3" align="center" prop="instrument_id" :label="$t('robot.position_type')">
              <template slot-scope="scope">
                <span>{{ $t('robot.cross') }}</span>
                <span :class="[scope.row.side === 'buy'?'side_color_green':'side_color_red']" style="margin-left: 5px;font-size: 12px">{{ scope.row.side === 'buy'?$t('robot.long'):$t('robot.short') }} {{ scope.row.leverage }}x</span>
              </template>
            </el-table-column>
            <el-table-column v-if="lishijiaoyi == 3" align="center" prop="avg_price" :label="$t('robot.avg_cost')">
              <template slot-scope="scope">{{ scope.row.avg_price | roundTwoFilter }}</template>
            </el-table-column>
            <el-table-column v-if="lishijiaoyi == 3" align="center" prop="close_price" :label="$t('robot.avg_close_price')">
              <template slot-scope="scope">{{ scope.row.close_price | roundTwoFilter }}</template>
            </el-table-column>
            <el-table-column v-if="lishijiaoyi == 3" align="center" min-width="119px" prop="close_price" :label="$t('robot.pl')+'|'+$t('robot.yield')">
              <template slot-scope="scope">
                <span :title="$options.filters['roundTwoFilter'](scope.row.profit) + '|' + $options.filters['roundTwoFilter'](scope.row.profit_rate)+'%'" style="word-break: break-word">{{ scope.row.profit | roundTwoFilter }}|{{ scope.row.profit_rate | roundTwoFilter }}%</span>
              </template>
            </el-table-column>
            <el-table-column v-if="lishijiaoyi == 3" align="center" prop="close_price" :label="$t('robot.max_open_int')">
              <template slot-scope="scope">{{ scope.row.max_open }}{{ $t('robot.cont') }}</template>
            </el-table-column>
            <el-table-column v-if="lishijiaoyi == 3" align="center" prop="close_price" :label="$t('robot.closed_position')">
              <template slot-scope="scope">{{ scope.row.close_size }}{{ $t('robot.cont') }}</template>
            </el-table-column>
            <el-table-column v-if="lishijiaoyi == 3" align="center" prop="close_time" :label="$t('robot.closed_time')">
              <template slot-scope="scope"><span style="word-break: break-word">{{scope.row.close_time}}</span></template>
            </el-table-column>
            <el-table-column v-if="lishijiaoyi == 3" align="center" prop="close_time" :label="$t('robot.state')">
              <template slot-scope="scope">{{ scope.row.status == 0? $t('robot.partial_close'):$t('robot.close_all') }}</template>
            </el-table-column>
          </el-table>
          <el-pagination
              v-if="lishijiaoyi == 2 || lishijiaoyi == 3"
              style="text-align: right;margin: 24px"
              layout="prev, pager, next"
              @current-change="handleRecommendCurrentChange"
              :page-size="10"
              :current-page="pageNum"
              :hide-on-single-page="capitalListSpaceTotal<=0"
              :total="capitalListSpaceTotal">
          </el-pagination>
        </div>
      </el-card>
    </div>
    <div v-else style="">
      <div style="height: 50px;background-color: white;position:relative;">
        <i class="el-icon-arrow-left" style="display:inline-block;font-size: 20px;line-height: 50px;margin-left: 20px" @click="$router.back()"></i>
        <span style="display:inline-block;position: absolute; margin: auto; left: 50%; top: 50%;transform: translate(-50%, -50%);">{{ $t('robot.strategy_details') }}<span style="display: inline-block;margin-left: 5px">(NO.{{items.rank<10?'0'+items.rank:items.rank}})</span></span>
        <img v-if="items.robot_type == 'bn'" style="position: absolute;right: 0;top: 0;width: 20px;height: 22px" src="../../assets/img/robot/binance_sign.svg">
        <img v-if="items.robot_type == 'ok'" style="position: absolute;right: 0;top: 0;width: 20px;height: 22px" src="../../assets/img/robot/okex_sign.svg">
      </div>
      <el-card style="" shadow="hover">
        <div class="detail_title" style="border-bottom: unset">
          <span style="font-size: 16px;margin-right: 9px;display: block;">{{ dataItem.coin }} <span style="color: #52C41A;margin-top: 0">⇀</span> {{ items.coin_two }} 永续 10X</span>
          <span style="padding: 4px 10px;font-size: 14px;margin: 5px 0 0 0;">{{ $t('robot.auto') }}：{{ $t('robot.grid_contract_strategy') }}</span>
          <span v-if="(items.strategyType == 0 && items.state == 1 && items.capital_one == 0)" style="display:inline-block;vertical-align:middle;font-size:12px;color: #AB54DB;margin-left:10px;margin-top: 5px;"><span class="el-icon-refresh-right" style="color: #AB54DB;margin-right:2px;font-size: 16px;animation: rotating 1.2s linear infinite;vertical-align: top;font-weight: unset;"></span>正在初始化</span>
          <img v-if="(items.state == 0)" style="vertical-align: middle;height: unset; width: 20px;margin-left:10px;margin-top: 5px;" src="../../assets/img/robot/running.gif">
          <span style="display: block;font-size: 12px;margin-left: 0px;margin-top: 5px">{{ $t('robot.create_time') }} {{ items.add_time }}</span>
          <div style="display: inline-block;position: absolute;right: 0;top: 0">
            <el-button v-if="items.state == 1" class="detail_title_button_w" style="display: block;margin: 3px;padding: 4px 15px;" :style="{width: language == 'English'?'76px': 'unset'}" @click="dialogPause(items)">{{ $t('robot.pause') }}</el-button>
            <el-button v-if="items.state == 2" class="detail_title_button_w" style="display: block;margin: 3px;padding: 4px 15px;" :style="{width: language == 'English'?'76px': 'unset'}" @click="dialogOpen(items)">{{ $t('robot.start') }}</el-button>
            <el-button class="detail_title_button_w" style="display: block;margin: 3px;padding: 4px 15px;" :style="{width: language == 'English'?'76px': 'unset'}" @click="delet(items)">{{ $t('robot.del') }}</el-button>
          </div>
        </div>
      </el-card>
      <el-card style="margin-top: 12px;min-height: 500px" shadow="hover">
        <el-tabs style="" v-model="celue_type" @tab-click="celueType">
          <el-tab-pane :label="$t('robot.revenue_details')" name="1"></el-tab-pane>
          <el-tab-pane :label="$t('robot.policy_information')" name="2"></el-tab-pane>
          <el-tab-pane :label="$t('robot.policy_delegation')" name="3"></el-tab-pane>
        </el-tabs>
        <div v-if="celue_type == 1" class="" style="position: relative">
          <el-tooltip effect="dark" :content="$t('robot.hedge_detail_tip1')" placement="top">
            <span style="font-size: 14px;padding-bottom: 1px; border-bottom: #b4bccc 1px dashed">{{ $t('robot.Total_revenue') }}(<span style="color: #72777E">{{ dataItem.coin }}</span>)</span>
          </el-tooltip>
          <span style="display:block;margin-top:6px;font-size: 22px;font-weight: 600">{{ dataItem.profit_withdraw }}</span>
          <div class="" style="position: absolute;top: 0; right: 0; text-align: right">
            <el-tooltip effect="dark" :content="$t('robot.hedge_detail_tip3')" placement="top">
              <span style="display:inline-block;font-size: 14px; margin-bottom: 0px;padding-bottom: 1px; border-bottom: #b4bccc 1px dashed;width: fit-content">{{ $t('robot.yield') }} | {{ $t('robot.total_annualization') }}</span>
            </el-tooltip>
            <span v-if="dataItem.profit_rate > 0" style="display:block;font-size: 19px;margin-top:6px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #52C41A;padding-right: 0px">{{ dataItem.profit_rate | roundEightFilter }}% | {{ dataItem.profit_rate_year | roundEightFilter }}%</span>
            <span v-if="dataItem.profit_rate == 0" style="display:block;font-size: 19px;margin-top:6px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #1E2329;padding-right: 0px">0.00% | 0.00%</span>
            <span v-if="dataItem.profit_rate < 0" style="display:block;font-size: 19px;margin-top:6px;font-family: PingFangSC-Semibold, PingFang SC;font-weight: 600;color: #FF4D4F;padding-right: 0px">{{ dataItem.profit_rate | roundEightFilter }}% | {{ dataItem.profit_rate_year | roundEightFilter }}%</span>
          </div>
          <div style="width: 100%;height: 1px;background: #EAECEF;margin: 15px 3% 15px 0"></div>
<!--          <div style="font-size: 14px;position:relative;margin-top: 10px;color: #72777E">-->
<!--            <span style="position:relative;vertical-align: middle">{{ $t('robot.profit_withdrawn') }}</span>-->
<!--&lt;!&ndash;            <span style="display:inline-block;font-size:12px;width: 12px;height: 12px; text-align: center;vertical-align: middle;margin-left: 5px;:  color: #72777E;border: 1px #72777E solid; border-radius: 50%">?</span>&ndash;&gt;-->
<!--            <span style="display:inline-block; position:absolute;right:0;color: #1E2329">{{ dataItem.profit_withdraw }} {{ dataItem.coin }}</span>-->
<!--          </div>-->
          <div style="font-size: 14px;position:relative;margin-top: 10px;color: #72777E">
            <span style="position:relative;vertical-align: middle">{{ $t('robot.billing_quantity') }}</span>
            <tooltip :content="$t('robot.hedge_detail_tip2')"></tooltip>
            <span style="display:inline-block; position:absolute;right:0;color: #1E2329">{{ dataItem.profit_times }} {{ $t('robot.order') }}</span>
          </div>
<!--          <div style="font-size: 14px;position:relative;margin-top: 10px;color: #72777E">-->
<!--            <span style="position:relative;vertical-align: middle">{{ $t('robot.position_floating') }}</span>-->
<!--            <tooltip :content="$t('robot.hedge_detail_tip4')"></tooltip>-->
<!--            <span style="display:inline-block; position:absolute;right:0;color: #1E2329">{{ dataItem.profit_lose }} {{ dataItem.coin }}</span>-->
<!--          </div>-->
          <div style="font-size: 14px;position:relative;margin-top: 10px;color: #72777E">
            <span style="position:relative;vertical-align: middle">{{ $t('robot.investment_amount') }}</span>
            <tooltip :content="$t('robot.hedge_detail_tip5')"></tooltip>
            <span style="display:inline-block; position:absolute;right:0;color: #1E2329">{{ dataItem.space }} {{ dataItem.coin }}</span>
          </div>
          <div style="font-size: 14px;position:relative;margin-top: 10px;color: #72777E">
            <span style="position:relative;vertical-align: middle">{{ $t('robot.exchange_assets') }}</span>
            <tooltip :content="$t('robot.hedge_detail_tip6')"></tooltip>
            <span style="display:inline-block; position:absolute;right:0;color: #1E2329">{{ dataItem.exchange_balance }} {{ dataItem.coin }}</span>
          </div>
<!--          <div style="font-size: 14px;position:relative;margin-top: 10px;color: #72777E">-->
<!--            <span style="position:relative;vertical-align: middle">{{ $t('robot.loss') }}</span>-->
<!--            &lt;!&ndash;            <span style="display:inline-block;font-size:12px;width: 12px;height: 12px; text-align: center;vertical-align: middle;margin-left: 5px;:  color: #72777E;border: 1px #72777E solid; border-radius: 50%">?</span>&ndash;&gt;-->
<!--            <span style="display:inline-block; position:absolute;right:0;color: #1E2329">{{ (dataItem.stop_loss || dataItem.stop_loss == 0)?dataItem.stop_loss !=0?(dataItem.stop_loss*100).toFixed(2)+'%':$t('robot.not_stop_loss'):'' }}</span>-->
<!--          </div>-->

<!--          <div style="font-size: 14px;position:relative;margin-top: 10px;color: #72777E">-->
<!--            <span style="position:relative;vertical-align: middle">{{ $t('robot.interference') }}</span>-->
<!--            &lt;!&ndash;            <span style="display:inline-block;font-size:12px;width: 12px;height: 12px; text-align: center;vertical-align: middle;margin-left: 5px;:  color: #72777E;border: 1px #72777E solid; border-radius: 50%">?</span>&ndash;&gt;-->
<!--            <span style="display:inline-block; position:absolute;right:0;color: #1E2329">{{ (dataItem.stop_surplus || dataItem.stop_surplus == 0)?dataItem.stop_surplus !=0?(dataItem.stop_surplus*100)+'%':$t('robot.not_stop_surplus'):'' }}</span>-->
<!--          </div>-->

          <div style="font-size: 14px;position:relative;margin-top: 10px;color: #72777E">
            <span style="position:relative;vertical-align: middle">{{ $t('robot.stop_loss') }}</span>
            <span style="display:inline-block; position:absolute;right:0;color: #1E2329">{{ (dataItem.stop_loss || dataItem.stop_loss == 0)?dataItem.stop_loss ==100?$t('robot.stop_loss'):dataItem.stop_loss==5?'部分止损':$t('robot.no_stop_loss'):'' }}</span>
          </div>

          <div style="font-size: 14px;position:relative;margin-top: 10px;color: #72777E">
            <span style="position:relative;vertical-align: middle">{{ $t('robot.make_up_loss_ratio') }}</span>
            <span style="display:inline-block; position:absolute;right:0;color: #1E2329">{{ dataItem.loss_transfer_rate }}%</span>
          </div>
          <div style="font-size: 14px;position:relative;margin-top: 10px;color: #72777E">
            <span style="position:relative;vertical-align: middle">{{ $t('robot.risk_level') }}</span>
            <span style="display:inline-block; position:absolute;right:0;color: #1E2329">{{dataItem.position_proportion?dataItem.position_proportion<0.6?'低':dataItem.position_proportion>0.8?'高':'中':''}}({{ dataItem.position_proportion?(dataItem.position_proportion * 100):'' }}%)</span>
          </div>
<!--          <span style="display:block;font-size: 14px;margin-top: 50px">{{ $t('robot.earnings_change') }}</span>-->
<!--          <charts-line style="margin-top: 10px"></charts-line>-->
        </div>

        <div v-if="celue_type == 2" class="" style="font-size: 14px;background-color: #FEFBF3">
<!--            <div style="display:inline-block;width: 100%; position:relative;background-color: #FEFBF3;border-radius: 2px;vertical-align: top;padding: 17px 0">-->
<!--              <span style="margin-bottom: 8px;vertical-align: top;margin-left: 10px">{{ $t('robot.futures_currently_held') }}</span>-->
<!--              <div style="display: inline-block;position: absolute;right: 10px;">-->
<!--                <div style="display: block;width: fit-content">-->
<!--                  <span class="total_revenue_content_bottom" style="display:inline-block;width: fit-content;color: #1E2329;margin-right: 6px;vertical-align: middle;font-size: 12px">{{ dataItem.symbol }}</span>-->
<!--                  <span style="background: #F0F4FB;;width: fit-content;border-radius: 2px;font-size:12px;padding:0 3px;color: #666666;display: inline-block">{{$t('robot.cross')}}</span>-->
<!--                  <span style="display: inline-block;background: rgba(28, 197, 141, 0.1);border-radius: 2px;font-size:12px;width: fit-content;margin-left: 6px;color: #1CC58D;padding: 0 3px">{{ $t('robot.long') }} {{ dataItem.leverage }}x</span>-->
<!--                  <span style="display: inline-block;font-size: 12px;color: #1E2329;width: fit-content;margin-left: 6px;">{{ dataItem.long_space }}{{ $t('robot.cont') }}</span>-->
<!--                </div>-->
<!--                <div style="display: block;text-align: left;width: fit-content;margin-left: 0px">-->
<!--                  <span class="total_revenue_content_bottom" style="display:inline-block;width: fit-content;color: #1E2329;margin-right: 6px;vertical-align: middle;font-size: 12px">{{ dataItem.symbol }}</span>-->
<!--                  <span style="background: #F0F4FB;;width: fit-content;border-radius: 2px;font-size:12px;padding:0 3px;color: #666666;display: inline-block">{{$t('robot.cross')}}</span>-->
<!--                  <span style="display: inline-block;background: rgba(255, 71, 63, 0.1);border-radius: 2px;font-size:12px;width: fit-content;margin-left: 6px;color: #FF473F;padding: 0 3px">{{ $t('robot.short') }} {{ dataItem.leverage }}x</span>-->
<!--                  <span style="display: inline-block;font-size: 12px;color: #1E2329;width: fit-content;margin-left: 6px;">{{ dataItem.short_space }}{{ $t('robot.cont') }}</span>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
          <div style="display:inline-block;width: 100%; position:relative;background-color: #FEFBF3;border-radius: 2px;vertical-align: top;padding: 17px 0">
            <span style="margin-bottom: 8px;vertical-align: top;margin-left: 10px;color: #72777E">{{ $t('robot.trade_currency') }}</span>
            <div style="display: inline-block;position: absolute;right: 10px;">
              <div style="display: block;width: fit-content">
                <span class="total_revenue_content_bottom" style="display:inline-block;width: fit-content;color: #1E2329;vertical-align: middle;font-size: 14px">{{ dataItem.symbol }}</span>
                <span style="display: inline-block;font-size:14px;margin-left: 6px;">{{ $t('robot.perpetual') }}</span>
                <span style="display: inline-block;font-size:14px;margin-left: 6px;">{{ dataItem.leverage }}X</span>
              </div>
            </div>
          </div>
          <div style="display:block;width: 100%; position:relative;background-color: #FEFBF3;border-radius: 2px;padding: 17px 0px;vertical-align: top">
            <div style="display: inline-block;width: 100%;vertical-align: middle;">
              <span style="margin:0 0px 8px 10px;display:inline-block;color: #72777E;">{{ $t('robot.create_time') }}</span>
              <span style="display:inline-block;margin-left: 10px;color:#1E2329;font-weight: 500;float: right;margin-right: 10px">{{ items.add_time }}</span>
            </div>
          </div>
        </div>
        <div v-if="celue_type == 3" class="" style="width: 100%;">
          <div class="everyday-top">
            <el-tabs v-model="lishijiaoyi" @tab-click="handleSelect">
              <el-tab-pane :label="lishitotal3?$t('robot.positions')+'('+ lishitotal3 +')':$t('robot.positions')" name="2"></el-tab-pane>
              <el-tab-pane :label="lishitotal4?$t('robot.position_history')+'('+ lishitotal4 +')':$t('robot.position_history')" name="3"></el-tab-pane>
              <el-tab-pane :label="lishitotal?$t('robot.current_delegation')+'('+ this.lishitotal +')':$t('robot.current_delegation')" name="0"></el-tab-pane>
              <el-tab-pane :label="lishitotal2?$t('robot.history_delegation')+'('+ this.lishitotal2 +')':$t('robot.history_delegation')" name="1"></el-tab-pane>
            </el-tabs>
          </div>
          <div v-if="(lishijiaoyi == 1 || lishijiaoyi == 3) && isMobile" class="robot-earn-mb" style="/*text-align: center*/ justify-content: space-between;">
            <el-date-picker
                style="display: inline-block;width: 48%"
                v-model="date1"
                type="date"
                :clearable="false"
                value-format="yyyy-MM-dd"
                range-separator="-"
                :placeholder="$t('robot.start_date')"
                @change="selectDateForMB1">
            </el-date-picker>
            <el-date-picker
                style="display: inline-block;width: 48%; float: right;"
                v-model="date2"
                type="date"
                :clearable="false"
                value-format="yyyy-MM-dd"
                range-separator="-"
                :placeholder="$t('robot.end_date')"
                @change="selectDateForMB2">
            </el-date-picker>
          </div>
          <el-select disabled v-if="lishijiaoyi == 0 || lishijiaoyi == 1" v-model="orderType" @change="changeOrderType" style="width: 100%;" :style="{marginTop: lishijiaoyi == 1?'10px': '0'}">
            <el-option :key="2" :label="$t('robot.limit_market')" :value="2"></el-option>
            <el-option :key="1" :label="$t('robot.stop_profit_stop_loss')" :value="1"></el-option>
          </el-select>
          <div class="robot-earn-mb" style="width: 100%; min-width: 281px">
            <div v-if="lishijiaoyi == 0">
            </div>
            <table v-if="isMobile" class="table" style="width: 100%;" >
              <tbody>
              <tr v-for="(item, i) in lishijiaoyiList_1">
                <td style="text-align: unset; width: 100%; font-size: 16px; border-bottom: #EAECEF 1px solid;padding:6px 0">
                  <div v-if="lishijiaoyi == 0 || lishijiaoyi == 1">
                    <div style="width: 100%;height: 26px; line-height: 26px;">
                      <span style="color: #000613;font-size:13px;font-weight: 550;">{{ dataItem.symbol }}</span>
                      <span style="margin-left: 10px;font-size: 13px" :class="[item.side === 'buy'?'side_color_green':'side_color_red']" v-if="item.type == 'open' && item.side == 'buy'">买入开多</span>
                      <span style="margin-left: 10px;font-size: 13px" :class="[item.side === 'buy'?'side_color_green':'side_color_red']" v-if="item.type == 'open' && item.side == 'sell'">卖出开空</span>
                      <span style="margin-left: 10px;font-size: 13px" :class="[item.side === 'sell'?'side_color_green':'side_color_red']" v-if="item.type == 'close' && item.side == 'buy'">卖出平多</span>
                      <span style="margin-left: 10px;font-size: 13px" :class="[item.side === 'sell'?'side_color_green':'side_color_red']" v-if="item.type == 'close' && item.side == 'sell'">买入平空</span>
                      <span style="font-size: 12px;color: #E3B936; margin-left: 10px; background: #FEFBF3;padding: 2px 5px;border-radius: 2px">{{ $t('robot.perpetual') }}</span>
                      <span style="font-size: 12px;color: #72777E; margin-left: 10px">{{ $t('robot.cross') }} {{ dataItem.leverage }}x</span>
                      <a v-if="lishijiaoyi == 1" style="float:right;color: #72777E;font-size: 12px">{{ (item.state == '-1')?$t('robot.cancelled'): (item.state == '0') ?$t('robot.pending_order') : $t('robot.completed') }}</a>
                    </div>
                    <div v-if="lishijiaoyi == 0 || lishijiaoyi == 1" style="width: 100%;height: 30px; line-height: 30px;font-size: 12px">
                      <span style="color:#72777E">{{ $t('robot.price') }}</span>
                      <span style="float: right;color: #000613">{{ item.side === 'buy' ? item.price : item.price }}({{dataItem.coin}})</span>
                    </div>
                    <div v-if="lishijiaoyi == 0" style="width: 100%;height: 30px; line-height: 30px;font-size: 12px">
                      <span style="color:#72777E">{{ $t('robot.num') }}</span>
                      <span style="float: right;color: #000613">{{ item.side === 'buy' ? item.size : item.size }}{{$t('robot.cont')}}</span>
                    </div>
                    <div v-if="lishijiaoyi == 1" style="width: 100%;height: 30px; line-height: 30px;font-size: 12px">
                      <span style="color:#72777E">{{ $t('robot.entrusted_quantity') }}</span>
                      <span style="float: right;color: #000613">{{ item.side === 'buy' ? item.size : item.size }}{{$t('robot.cont')}}</span>
                    </div>
                    <div v-if="lishijiaoyi == 0 || lishijiaoyi == 1" style="width: 100%;height: 30px; line-height: 30px;font-size: 12px">
                      <span style="color:#72777E">{{ $t('robot.order_Value') }}</span>
                      <span style="float: right;color: #000613">{{ item.total_money }}({{dataItem.coin}})</span>
                    </div>
                    <div v-if="lishijiaoyi == 0" style="width: 100%;height: 30px; line-height: 30px;font-size: 12px">
                      <span style="color:#72777E">{{ $t('robot.date') }}</span><span style="float: right;color: #000613">{{ item.create_time }}</span>
                    </div>
                    <div v-if="lishijiaoyi == 1" style="width: 100%;height: 30px; line-height: 30px;font-size: 12px">
<!--                      <span style="color:#72777E">{{ $t('robot.time') }}</span><span style="float: right;color: #000613">{{ item.create_time }}</span>-->
                      <span style="color:#72777E">{{ $t('robot.entrusted_time') }}</span><span style="float: right;color: #000613">{{ item.create_time }}</span>
                    </div>
                    <div v-if="lishijiaoyi == 1" style="width: 100%;height: 30px; line-height: 30px;font-size: 12px">
                      <span style="color:#72777E">{{ $t('robot.transaction_time') }}</span><span style="float: right;color: #000613">{{ item.update_time }}</span>
                    </div>
                  </div>

                  <div v-if="lishijiaoyi == 2 || lishijiaoyi == 3">
                    <div style="width: 100%;height: 26px; line-height: 26px;">
                      <span style="color: #000613;font-size:13px;font-weight: 550;">{{ dataItem.symbol }}</span>
                      <span style="font-size: 12px;color: #E3B936; margin-left: 10px; background: #FEFBF3;padding: 2px 5px;border-radius: 2px">{{ $t('robot.perpetual') }}</span>
                      <span style="font-size: 12px;color: #72777E; margin-left: 10px">{{ $t('robot.cross') }}</span>
                      <span :class="[item.side === 'buy'?'side_color_green':'side_color_red']" style="margin-left: 5px;font-size: 12px">{{ item.side === 'buy'?$t('robot.long'):$t('robot.short') }} {{ dataItem.leverage }}x</span>
                      <a v-if="lishijiaoyi == 3" style="float:right;color: #72777E;font-size: 12px">{{ item.status == 0? $t('robot.partial_close'):$t('robot.close_all') }}</a>
                    </div>
                    <div v-if="lishijiaoyi == 2" style="width: 100%;height: 30px; line-height: 30px;font-size: 12px">
                      <span style="color:#72777E">{{ $t('robot.total_position') }}</span>
                      <span style="float: right;color: #000613">{{ item.total_size }}{{$t('robot.cont')}}</span>
                    </div>
                    <div v-if="lishijiaoyi == 2" style="width: 100%;height: 30px; line-height: 30px;font-size: 12px">
                      <span style="color:#72777E">{{ $t('robot.avg_price') }}</span>
                      <span style="float: right;color: #000613">{{ item.avg_price }}</span>
                    </div>
                    <div v-if="lishijiaoyi == 2" style="width: 100%;height: 30px; line-height: 30px;font-size: 12px">
                      <span style="color:#72777E">{{ $t('robot.IMR') }}</span>
                      <span style="float: right;color: #000613">{{ item.initial_margin }}</span>
                    </div>
                    <div v-if="lishijiaoyi == 2" style="width: 100%;height: 30px; line-height: 30px;font-size: 12px">
                      <span style="color:#72777E">{{ $t('robot.mark_price') }}</span>
                      <span style="float: right;color: #000613">{{ item.mark_price }}</span>
                    </div>
                    <div v-if="lishijiaoyi == 2" style="width: 100%;height: 30px; line-height: 30px;font-size: 12px">
                      <span style="color:#72777E">{{ $t('robot.margin_rate') }}</span><span style="float: right;color: #000613">{{ item.margin_ratio?item.margin_ratio:'0.00' }}%</span>
                    </div>
                    <div v-if="lishijiaoyi == 2" style="width: 100%;height: 30px; line-height: 30px;font-size: 12px">
                      <span style="color:#72777E">{{ $t('robot.liq_price') }}</span><span style="float: right;color: #000613">{{ item.force_price?item.force_price:'--' }}</span>
                    </div>

                    <div v-if="lishijiaoyi == 3" style="width: 100%;height: 30px; line-height: 30px;font-size: 12px">
                      <span style="color:#72777E">{{ $t('robot.avg_cost') }}</span><span style="float: right;color: #000613">{{ item.avg_price | roundTwoFilter }}</span>
                    </div>
                    <div v-if="lishijiaoyi == 3" style="width: 100%;height: 30px; line-height: 30px;font-size: 12px">
                      <span style="color:#72777E">{{ $t('robot.avg_close_price') }}</span><span style="float: right;color: #000613">{{ item.close_price | roundTwoFilter }}</span>
                    </div>
                    <div v-if="lishijiaoyi == 2 || lishijiaoyi == 3" style="width: 100%;height: 30px; line-height: 30px;font-size: 12px">
                      <span style="color:#72777E">{{ $t('robot.pl') }}({{ $t('robot.yield') }})</span><span style="float: right;color: #000613">{{ item.profit }}({{ item.profit_rate | roundTwoFilter }}%)</span>
                    </div>
                    <div v-if="lishijiaoyi == 3" style="width: 100%;height: 30px; line-height: 30px;font-size: 12px">
                      <span style="color:#72777E">{{ $t('robot.max_open_int') }}</span><span style="float: right;color: #000613">{{ item.max_open }}{{ $t('robot.cont') }}</span>
                    </div>
                    <div v-if="lishijiaoyi == 3" style="width: 100%;height: 30px; line-height: 30px;font-size: 12px">
                      <span style="color:#72777E">{{ $t('robot.closed_position') }}</span><span style="float: right;color: #000613">{{ item.close_size }}{{ $t('robot.cont') }}</span>
                    </div>
                    <div v-if="lishijiaoyi == 3" style="width: 100%;height: 30px; line-height: 30px;font-size: 12px">
                      <span style="color:#72777E">{{ $t('robot.opened') }}</span><span style="float: right;color: #000613">{{ item.start_time }}</span>
                    </div>
                    <div v-if="lishijiaoyi == 3 && (item.close_time)" style="width: 100%;height: 30px; line-height: 30px;font-size: 12px">
                      <span style="color:#72777E">{{ $t('robot.closed_time') }}</span><span style="float: right;color: #000613">{{ item.close_time }}</span>
                    </div>
                  </div>

                </td>
              </tr>
              </tbody>
            </table>
            <div v-if="isMobile" style="height: 22px;margin-top: 10px">
              <span style="font-size:14px; color: #000613; line-height: 22px;">{{ $t('robot.total') }} <span>{{ capitalListTotal }}</span> {{ $t('robot.strip') }}</span>
              <el-pagination
                  style="float:right;padding-right: 0px;"
                  small
                  layout="prev, pager, next"
                  @current-change="handleRecommendCurrentChange"
                  :page-size="10"
                  :current-page="pageNum"
                  :hide-on-single-page="capitalListTotal<=0"
                  :total="capitalListTotal">
              </el-pagination>
            </div>
          </div>
        </div>

      </el-card>
    </div>

    <!-- 暂停策略 -->
    <el-dialog
        class="details_dialog"
        :title="$t('robot.pause') + $t('robot.grid_contract_strategy')"
          :visible.sync="dialogPauseState"
        :close-on-click-modal="true"
        :close-on-press-escape="true"
        width="600px"
        style="height: auto;overflow: unset;padding-top: 0px">
      <el-divider></el-divider>
      <div style="padding: 0 20px">
        <span class="del_grid_span" style="display:inline-block;background-color: #F4F8FF;color: #AB54DB; padding: 10px; width: -webkit-fill-available;">{{ $t('robot.pause_contract_tip') }}</span>
        <span style="display:inline-block;margin: 20px 0">{{ $t('robot.liquidation_risk_tip') }}</span>
        <el-checkbox style="display: block;" v-model="check_pause"><span style="display: inline-block;word-break: break-word;white-space: break-spaces;">{{ $t('robot.pause_risk_tip')}}</span></el-checkbox>
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button class="robot_btn" type="" style="width: 90px;color: #72777E !important;" @click="dialogPauseState = false">{{ $t('robot.cancel') }}</el-button>
    <el-button class="go_and_open" type="primary" style="width: 90px;text-align: center" @click="pauseContract()">{{ $t('robot.confirm') }}</el-button>
  </span>
    </el-dialog>

<!--    &lt;!&ndash; 删除策略 &ndash;&gt;-->
<!--    <el-dialog-->
<!--        class="details_dialog details_dialog_del"-->
<!--        :title="$t('robot.del') + $t('robot.grid_contract_strategy')"-->
<!--        :visible.sync="dialogDel"-->
<!--        :close-on-click-modal="true"-->
<!--        :close-on-press-escape="true"-->
<!--        width="600px"-->
<!--        style="height: auto;overflow: unset;padding-top: 0px">-->
<!--      <el-divider></el-divider>-->
<!--      <div style="padding: 0 20px">-->
<!--        <span class="del_grid_span" style="display:inline-block;background-color: #F4F8FF;color: #AB54DB; padding: 10px; width: -webkit-fill-available;">{{ $t('robot.del_contract_tip') }}</span>-->
<!--        <span style="display:inline-block;margin: 20px 0 10px">{{ $t('robot.are_you_cancel_order') }}</span>-->
<!--        <div style="">-->
<!--          <div style="white-space: pre;">-->
<!--            <el-radio style="display: inline-block;color: #1E2329;vertical-align: top" v-model="cancelState" label="1">{{ $t('robot.yes') }}</el-radio>-->
<!--            <span :class="language == 'English'?'english_radio': 'unset'" style="display: inline-block;margin-left: 12px;color: #72777E;word-break: break-word;white-space: break-spaces;">{{ $t('robot.cancel_contract_all_no_deal') }}</span>-->
<!--          </div>-->
<!--          <div style="white-space: pre;margin-top: 12px;">-->
<!--            <el-radio style="display: inline-block;color: #1E2329;vertical-align: top" v-model="cancelState" label="2">{{ $t('robot.no') }}</el-radio>-->
<!--            <span :class="language == 'English'?'english_radio': 'unset'" style="display: inline-block;margin-left: 12px;color: #72777E;word-break: break-word;white-space: break-spaces;">{{ $t('robot.retain_contract_all_no_deal') }}</span>-->
<!--          </div>-->

<!--        </div>-->
<!--        <span style="display:inline-block;margin: 20px 0 10px">{{ $t('robot.are_you_close_position') }}</span>-->
<!--        <div style="">-->
<!--          <div style="white-space: pre;">-->
<!--            <el-radio style="display: inline-block;color: #1E2329;vertical-align: top" v-model="positionState" label="1">{{ $t('robot.yes') }}</el-radio>-->
<!--            <span :class="language == 'English'?'english_radio': 'unset'" style="display: inline-block;margin-left: 12px;color: #72777E;word-break: break-word;white-space: break-spaces;">{{ $t('robot.closed_market_price_all') }}</span>-->
<!--          </div>-->
<!--          <div style="white-space: pre;margin-top: 12px;">-->
<!--            <el-radio style="display: inline-block;color: #1E2329;vertical-align: top" v-model="positionState" label="2">{{ $t('robot.no') }}</el-radio>-->
<!--            <span :class="language == 'English'?'english_radio': 'unset'" style="display: inline-block;margin-left: 12px;color: #72777E;word-break: break-word;white-space: break-spaces;">{{ $t('robot.retain_market_price_all') }}</span>-->
<!--          </div>-->

<!--        </div>-->
<!--      </div>-->
<!--      <span slot="footer" class="dialog-footer">-->
<!--    <el-button class="robot_btn" type="" style="width: 90px;color: #72777E !important;" @click="dialogDel = false">{{ $t('robot.cancel') }}</el-button>-->
<!--    <el-button class="go_and_open" type="primary" style="width: 90px;text-align: center" @click="pauseContract()">{{ $t('robot.confirm') }}</el-button>-->
<!--  </span>-->
<!--    </el-dialog>-->
  </div>

</template>

<script>
import {
  delBb,
  deleteSupremeHedge,
  delGrid,
  delSj,
  delUb,
  getOrderListByStateAndSymbol,
  getSellOrdersByState,
  getSellOrdersByState2,
  getSupremeHedge,
  startSupremeHedge,
  stopSupremeHedge,
  getSupremeHedgeOrder,
  getSupremeHedgeSpace
} from "../../server/robot";
import ChartsLine from "./chartsLine";
import Tooltip from "../common/tooltip";

export default {
  name: "Robot_auto_detail",
  components: {Tooltip, ChartsLine},
  data(){
    return{
      dateValue: [],
      date1: '',
      date2: '',
      capitalListTotal: 10,
      capitalListSpaceTotal: 10,
      pageNum: 1,
      totalPage: 0,
      lishijiaoyi: '2', //0：當前委托  1：歷史委托  2：歷史成交
      lishitotal: 0,
      lishitotal2: 0,
      lishitotal3: 0,
      lishitotal4: 0,
      lishijiaoyiObj: {},
      lishijiaoyiList_1: [1],
      isMobile: false,
      switchValue: 2,
      items: {},
      orderId:'',
      celue_type: '1',
      runTime: '',
      runTimeToHour: '',
      robotType: 0,
      robotState: '',
      dialogPauseState: false,
      dialogDel: false,
      check_pause: false,
      language: '',
      cancelState: '1',
      positionState: '1',
      dataItem: {},
      orderType: 2,
    }
  },

  mounted() {
    console.log('lishijiaoyi', this.lishijiaoyi)
    this.orderId = this.$route.query.id
    this.switchValue = this.$route.query.switchValue;
    this.robotState = this.$route.query.robotState;
    this.language = localStorage.getItem('language')
    this.items = sessionStorage.getItem('detailItem')?JSON.parse(sessionStorage.getItem('detailItem')):{}
    if (this.items.robot_type == 'bn'){
      this.robotType = 0;
    } else {
      this.robotType = 1;
    }
    this.getRuntime();

    if (this.$util._isMobile()){
      if (this.$util._isMobile[0] == 'iPad'){
        this.isMobile = false
      }
      this.isMobile = true;
    } else {
      this.isMobile = false
    }
    this.getSupremeHedge()
    this.initData();
  },

  methods:{
    initData() {
      this.getSellOrdersByState2();
      var timeSpace = setTimeout(()=>{
        this.getSupremeHedgeSpace()
        clearTimeout(timeSpace)
      },500)
    },
    selectDate() {
      this.date1 = this.dateValue[0]
      this.date2 = this.dateValue[1]
      this.pageNum = 1;
      this.lishijiaoyiList_1 = [];
      if (this.lishijiaoyi == 1){
        this.getSellOrdersByState2();
      } else if (this.lishijiaoyi == 3){
        this.getSupremeHedgeSpace()
      }

    },
    selectDateForMB1() {
      if (this.date2 == null || this.date2 == '') {
        return;
      } else {
        if (new Date(this.date2) < new Date(this.date1)) {
          this.$message.warning(this.$t('robot.end_time_cannot_less_than_start'))
          return
        }
        this.pageNum = 1
        this.lishijiaoyiList_1 = [];
        if (this.lishijiaoyi == 1){
          this.getSellOrdersByState2();
        } else if(this.lishijiaoyi == 3){
          this.getSupremeHedgeSpace()
        }
      }
    },
    selectDateForMB2() {
      if (this.date1 == null || this.date1 == '') {
        return;
      } else {
        if (new Date(this.date2) < new Date(this.date1)) {
          this.$message.warning(this.$t('robot.start_time_cannot_creater_than_end'))
          return
        }
        this.pageNum = 1
        this.lishijiaoyiList_1 = [];
        if (this.lishijiaoyi == 1){
          this.getSellOrdersByState2();
        } else if(this.lishijiaoyi == 3){
          this.getSupremeHedgeSpace()
        }
      }
    },
    handleSelect() {
      if (this.lishijiaoyi == 1 || this.lishijiaoyi == 3){
        if (this.date1 == ''){
          this.date1 = this.$util.currentDate('-')
        }
        if (this.date2 == ''){
          this.date2 = this.$util.currentDate('-')
        }
        if (!this.isMobile){
          if (this.dateValue == [] || this.dateValue.length == 0){
            this.dateValue.push(this.date1)
            this.dateValue.push(this.date2)
          }
        }
      } else {
        this.date1 = ''
        this.date2 = ''
      }
      this.pageNum = 1;
      this.lishijiaoyiList_1 = [];
      if (this.lishijiaoyi == 0 || this.lishijiaoyi == 1) {
        this.getSellOrdersByState2();
      } else {
        this.getSupremeHedgeSpace()
      }
    },

    changeOrderType(){
      this.getSellOrdersByState2()
    },

    async getSupremeHedge(){
      const res = await getSupremeHedge(this.robotType, this.items.sid)
      if (!res) return
      if (res.success) {
        if (res.data){
          this.dataItem = res.data
        }
      }
    },

    // 獲取當前委托
    async getSellOrdersByState2() {
      console.log(this.lishijiaoyi)
      var state = 0
      if (this.lishijiaoyi == 0 || this.lishijiaoyi == 2){
        state = 0
      } else if (this.lishijiaoyi == 1){
        state = 1
      }
      const res = await getSupremeHedgeOrder(state,this.date1,this.date2, this.pageNum,10,this.robotType, this.orderType, this.items.sid)
      if (res.success) {
        if (this.lishijiaoyi == 0 || this.lishijiaoyi == 1){
          this.lishijiaoyiList_1 = res.data.list;
          this.lishijiaoyiList_1.forEach((item, index)=> {
            if (item.side == 'buy') {
              item.sideName = this.$t('robot.buy');
            } else if (item.side == 'sell' || item.side == 'ration') {
              item.sideName = this.$t('robot.sell');
            }
          })
        }

        if (this.lishijiaoyi == 2 || this.lishijiaoyi == 0){
          this.lishitotal = res.data.totalRow
        }
        if(this.lishijiaoyi == 1){
          this.lishitotal2 = res.data.totalRow
        }
        this.capitalListTotal = res.data.totalRow
      } else {
        this.$message.error(res.message)
      }
    },

    getSupremeHedgeSpace(){
      var state = ''
      if (this.lishijiaoyi == 2){
        state = '0'
      } else if(this.lishijiaoyi == 3){
        state = '1'
      }
      getSupremeHedgeSpace(state,this.date1,this.date2, this.pageNum,10,this.robotType, this.items.sid).then(res =>{
        if (!res) return
        if (res.success){
          if (res.data){
            if (this.lishijiaoyi == 2){
              this.lishitotal3 = res.data.totalRow
            } else if(this.lishijiaoyi == 3) {
              this.lishitotal4 = res.data.totalRow
            }
            this.lishijiaoyiList_1 = res.data.list
            this.capitalListTotal = res.data.totalRow
            this.capitalListSpaceTotal = res.data.totalRow
          }
        }

      })
    },

    handleRecommendCurrentChange(e) {
      this.pageNum = e;
      if (this.lishijiaoyi == 0 || this.lishijiaoyi == 1) {
        this.getSellOrdersByState2()
      } else {
        this.getSupremeHedgeSpace()
      }
    },
    // loadLishijiaoyi_1_mb(num) {
    //   this.pageNum = num;
    //   if (this.lishijiaoyi == 0 || this.lishijiaoyi == 1) {
    //     this.getSellOrdersByState2()
    //   } else {
    //     this.getSupremeHedgeSpace()
    //   }
    // },
    stateFormat2(row, column) {
      if (row.side === 'buy') {
        return row.price
      } else {
        return row.price
      }
    },
    stateFormat3(row, column) {
      if (row.state == '-1') {
        return this.$t('robot.cancelled')
      } else if (row.state == '0') {
        return this.$t('robot.pending_order')
      } else {
        return this.$t('robot.completed')
      }
    },

    celueType(){

    },

    getRuntime(){
      this.runTime = this.$util.formatDuring(new Date().getTime() - new Date(this.items.add_time))
      this.runTimeToHour = this.$util.formatDuringToHour(new Date().getTime() - new Date(this.items.add_time))
    },


    dialogPause(item){
      this.dialogPauseState = true
    },

    delet(item){
      this.$alert((this.robotState == 2?`<span style="display: block;margin-top: 5px;color: #606266;line-height: 16px">${this.$t(`robot.delete_sj_for_closed_bot`)}</span>`: `<span style="display: block;margin-top: 5px;color: #606266;line-height: 16px">${this.$t(`robot.del_hedge`)}</span>`), '', {
        dangerouslyUseHTMLString: true,
        showCancelButton: true
      }).then(async _ => {
        const res = await deleteSupremeHedge(this.robotType)
        if (res.success) {
          this.$message.success(this.$t('robot.del_success'))
          // await this.getUserStrategyListAll();
          this.$router.back()
        } else {
          this.$message.error(res.message)
        }
      }).catch(_ => {});
    },

    async pauseContract(){
      if (!this.check_pause){
        this.$message.warning(this.$t('robot.select_risk_tip'))
        return
      }
      const res = await stopSupremeHedge(this.robotType)
      if (!res) return
      if (res.success){
        this.$message.success(this.$t('robot.strategy') + this.$t('robot.paused'))
        this.dialogPauseState = false
        this.items.state = 2
      } else {
        this.$message.error(res.message)
      }
    },

    async dialogOpen(){ // 开启合约
      const res = await startSupremeHedge(this.robotType)
      if (!res) return
      if(res.success){
        this.$message.success(this.$t('robot.strategy') + this.$t('robot.turned_on'))
        this.items.state = 1
      } else {
        this.$message.error(res.message)
      }
    },

  },

}
</script>

<style scoped>

.detail_title {
  position: relative;
}

.detail_title img:nth-of-type(1) {
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 7px;
}

.detail_title span:nth-of-type(1) {
  font-size: 16px;
  font-weight: 600;
  display: inline-block;
  color: black;
  margin-top: 2px;
  vertical-align: middle;
}

.detail_title span:nth-of-type(2) {
  font-size: 14px;
  font-family: PingFangTC-Regular, PingFangTC;
  font-weight: 400;
  display: inline-block;
  color: #E3B936;
  vertical-align: middle;
  background: #FEFBF3;
  padding: 6px 12px;
  border-radius: 2px;
  margin-left: 9px;
}

.detail_title img:nth-of-type(2) {
  width: 14px;
  height: 14px;
  margin-left: 24px;
  vertical-align: middle;
}

.detail_title span:nth-of-type(3) {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #72777E;
  margin-left: 12px;
  vertical-align: middle;
}

.detail_title div {
  display: inline-block;
  position: absolute;
  right: 0px;
}

.detail_title_button_w {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #AB54DB !important;
  padding: 9px 17px;
  background: #FFFFFF;
  border-radius: 2px;
  border: 1px solid #AB54DB;
}

.detail_title_button_b {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  padding: 10px 18px;
  background: #AB54DB;
  border-radius: 2px;
  border: unset;
}

.total_revenue {

}

.total_revenue_content {
  vertical-align: top;
  width: 100%;
  display: inline-block;
}

.total_revenue_content div:nth-of-type(1)>div {
  display: inline-block;
  width: 48.5%;
}

.total_revenue_content div:nth-of-type(1)>div:nth-of-type(2) {
  text-align: right;
  display: inline-block;
  padding-right: 3%;
}

.total_revenue_content span {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #72777E;
  display: block;
}

.total_revenue_content_bottom{
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #1E2329;
}

.side_color_red {
  color: #FF4D4D
}

.side_color_green {
  color: #52C41A
}

>>>.el-tabs__nav-wrap::after{
  height: 1px;
}

>>>.el-tabs__item.is-active {
  color: #AB54DB;
}

>>>.el-tabs__active-bar{
  background-color: #AB54DB;
}

>>>.el-table .el-table__cell {
  padding: 8px 0;
}

.details_dialog >>>.el-dialog__body{
  padding: 10px 0px 0 0px;
}

.details_dialog >>>.el-divider--horizontal{
  margin-top: 0;
}

@media screen and (max-width: 900px) {
  .details_dialog >>>.el-dialog__body{
    padding: 10px 0px 0 0px !important;
  }

  .details_dialog >>>.el-divider--horizontal{
    margin-top: 0;
  }

  .robot-pc {
    display: none;
  }
  >>>.el-tabs__item {
    padding: 0 9px;
  }

  .details_dialog >>>.el-checkbox__input{
    vertical-align: top;
    margin-top: 3px;
  }

  .english_radio{
    width: 260px;
  }
}

@media screen and (min-width: 900px) {
  .robot-pc {
    display: block;
  }
}

>>>.el-checkbox__inner{
  background-color: white;
  border-color: #606266;
}

.details_dialog_del >>>.el-radio{
  margin-right: 0;
  margin-top: 1px;
}

>>>.el-dialog__footer{
  padding-top: 16px;
}

</style>
